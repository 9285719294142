import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

const PortfolioPage = ({ data, location }) => {
  const portfolioPiece = data.allPrismicPortfolioPiece.edges
  const [_background, setTheBackground] = useState("")

  useEffect(() => {
    document.body.style.background = _background
  })

  return (
    <>
      <Helmet
        title={`${data.site.siteMetadata.title} - ${
          location.pathname.split("/")[1]
        }`}
        meta={[
          {
            name: `description`,
            content: data.site.siteMetadata.description,
          },
        ]}
      />
      <div className="portfolio">
        {portfolioPiece.map((piece, i) => (
          <Link
            key={i}
            className="portfolio-piece"
            to={`${piece.node.uid}`}
            onMouseOver={() =>
              setTheBackground(`${piece.node.data.portfolio_background}`)
            }
            onMouseLeave={() => setTheBackground("#fff")}
          >
            <div className="portfolio-piece-title">
              <p>{piece.node.data.portfolio_title[0].text}</p>
            </div>
          </Link>
        ))}
        <div className="portfolio-piece">
          <div className="portfolio-piece-title">
            <p>
              Like what you see? Want to see your project here? Give me a shout:{" "}
              <a href="mailto:f.gisonni@gmail.com">f.gisonni@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

// Queried data from Prismic
export const query = graphql`
  query {
    allPrismicPortfolioPiece {
      edges {
        node {
          data {
            portfolio_title {
              type
              text
            }
            portfolio_desc {
              type
              text
            }
            portfolio_web_link {
              link_type
              url
            }
            portfolio_image {
              url
            }
            portfolio_background
          }
          uid
        }
      }
    }
    site {
      siteMetadata {
        description
        title
        author
        backgrounds {
          black
          normal
          transparent
        }
      }
    }
  }
`

export default PortfolioPage
